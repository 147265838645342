import {types} from "mobx-state-tree";
import {api, v3Endpoint} from "../../api";
import {flow} from "mobx";
import {$spinner} from "../spinner";
import {$products} from "./productsInBundle";
import iziToast from "izitoast";

export const bundleTypes = [
  {value: 'info', title: 'Информационный'},
  {value: 5, title: 'Промо'}
];

export const positions = [
  {value: 'top', title: 'Верх'},
  {value: 'bottom', title: 'Низ (по умолчанию)'}
];

export const $bundles = types
  .model({
    list: types.frozen([]),
    limit: types.optional(types.number, 50),
    offset: types.optional(types.number, 0),
    hasMore: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    fetchBundles: flow(function* (type) {
      try {
        $spinner.show();
        const res = yield api(v3Endpoint)
          .get(`bundles`, {
            params: {
              limit: self.limit,
              offset: self.offset,
              type: type,
            },
          })
          .then((res) => res.data);

        self.setHasMore(res.length >= self.limit);
        self.setList(res);
        $spinner.hide();
      } catch (err) {
        $spinner.hide();
        console.error("fetch bundle list: ", err);
        throw err;
      }
    }),

    create: flow(function* (params) {
      try {
        const res = yield api(v3Endpoint)
          .post(`bundles`, params)
          .then((res) => res.data);
        self.fetchBundles();
        $products.setCurrentBundle(res);
      } catch (err) {
        iziToast.error({
          message: "Ошибка сохранения",
        });
        throw err;
      }
    }),

    update: flow(function* (id, params) {
      try {
        const updatedBundle = yield api(v3Endpoint)
          .put(`bundles/${id}`, params)
          .then((res) => res.data);

        self.setList(self.list.map(bundle =>
          bundle.id === id ? updatedBundle : bundle
        ));

        $products.setCurrentBundle({});
      } catch (err) {
        iziToast.error({
          message: "Ошибка сохранения",
        });
        throw err;
      }
    }),

    delete: flow(function* (id) {
      try {
        yield api(v3Endpoint)
          .delete(`bundles/${id}`)
          .then((res) => res.data);

        self.setList(self.list.filter(bundle => bundle.id !== id));

        iziToast.success({
          message: "Бандл успешно удален"
        });
      } catch (error) {
        iziToast.error({
          message: "Ошибка при удалении бандла"
        });
        throw error;
      }
    }),
    setList(data) {
      self.list = data;
    },
    paginate() {
      self.offset += self.limit;
      self.fetchBundles("paginate");
    },
    resetPaginate() {
      self.offset = 0;
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    setLimit(data) {
      self.limit = data;
    },
  }))
  .create();
