import {Grid, Skeleton} from "@mui/material";
import {HexColorPicker} from "react-colorful";
import React from "react";

export const ColorPicker = ({title, type, setColor, textColor, bgColor}) => {
  return (
    <div style={{marginBottom: '24px'}}>
      {/*<Grid container spacing={0}>*/}
      {/*  <Grid item xs={6}>*/}
            <HexColorPicker color={type === 'text' ? textColor : bgColor} onChange={setColor}/>
        {/*</Grid>*/}
      {/*</Grid>*/}
    </div>
  );
}
