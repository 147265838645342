import React, { useState, useEffect } from "react";
import {Modal} from "ui-components/modal";
import {$modals} from "store/modals";
import {observer} from "mobx-react-lite";
import ReactJson from 'react-json-view';
import {$transactions} from "store/transactions/transactions";

const ReceiptLink = ({params}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [receiptExists, setReceiptExists] = useState(false);

  // Функция для форматирования даты
  const getT = (dateString) => {
    if (!dateString) return '';
    
    // Создаем объект даты
    const date = new Date(dateString);

    // Добавляем 3 часа
    date.setHours(date.getHours() + 3);

    // Получаем компоненты даты
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    // Формируем итоговую строку
    return `${year}${month}${day}T${hours}${minutes}`;
  }

  // Проверяем наличие чека через API
  useEffect(() => {
    const checkReceipt = async () => {
      if (!params || !params.fiscal_storage_number) {
        setIsLoading(false);
        return;
      }

      const fn = params.fiscal_storage_number.trim();
      const i = params.fiscal_document_number;
      const fp = params.fiscal_attribute;
      const s = params.settlements && params.settlements[0] ? params.settlements[0].amount.value : '';
      const t = getT(params.registered_at);

      if (!fn || !i || !fp || !s || !t) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      try {
        // Используем новый метод из стора transactions
        const exists = await $transactions.check1OfdReceipt({
          t, s, fn, i, fp, n: 1
        });
        setReceiptExists(exists);
      } catch (error) {
        console.error('Ошибка при проверке чека:', error);
        setReceiptExists(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkReceipt();
  }, [params]);

  // Обработка случаев отсутствия данных
  if (!params) {
    return 'Ошибка. Чек не найден';
  }

  if (!params.fiscal_storage_number) {
    return <>fiscal data not found</>;
  }

  // Получаем параметры для ссылок
  const fn = params.fiscal_storage_number.trim();
  const i = params.fiscal_document_number;
  const fp = params.fiscal_attribute;
  const s = params.settlements[0].amount.value;
  const t = getT(params.registered_at);

  // Отображаем состояние загрузки
  if (isLoading) {
    return 'Проверка доступности чека...';
  }

  // Отображаем соответствующую ссылку
  if (receiptExists) {
    return (
      <div>
        <a
          href={`https://consumer.1-ofd.ru/ticket?t=${t}&s=${s}&fn=${fn}&i=${i}&fp=${fp}&n=1`}
          target="_blank" rel="noopener noreferrer">
          Чек (1 ОФД)
        </a>
      </div>
    );
  } else {
    return (
      <div>
        <a
          href={`https://lk.platformaofd.ru/web/noauth/cheque?fn=${fn}&fp=${fp}&i=${i}`}
          target="_blank" rel="noopener noreferrer">
          Чек (Платформа)
        </a>
      </div>
    );
  }
}

const ReceiptDetailsModal = observer(() => {
  const params = $modals.params;

  return (
    <Modal
      title="Информация о чеке от Yookassa"
      subheader={<ReceiptLink params={params}/>}
    >
      <ReactJson
        displayDataTypes={false}
        displayObjectSize={false}
        enableClipboard={false}
        src={params}
        theme="rjv-default"
        collapsed={false}/>
    </Modal>
  );
});

export default ReceiptDetailsModal;
