import React from "react";
import exampleCard from "./img/example_card.png";
import exampleListIos from "./img/example_list_ios.png";
import exampleListAndroid from "./img/example_list_android.png";
import styled from "@emotion/styled";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from 'react-responsive-carousel';

const ExampleContainer = styled.div`
    margin-bottom: 24px;
    position: relative;
`

const ExampleBundle = styled.div`
    position: absolute;
    background: ${props => props.bgColor ? props.bgColor : '#000'};
    color: ${props => props.textColor ? props.textColor : '#FFF'};
    padding: 0 6px;
    align-items: center;
    display: flex;
    font-size: 10px;
    line-height: 16px;
    border-radius: 5px;
    ${props => props.customStyles}
`

export const Example = ({title, textColor, bgColor}) => {

  const images = [
    {
      src: exampleCard,
      bundleStyles: `
        top: 286px;
        left: 40px;
      `
    },
    {
      src: exampleListIos,
      bundleStyles: `
        top: 248px;
        left: 36px;
      `
    },
    {
      src: exampleListAndroid,
      bundleStyles: `
        left: 130px;
        top: 342px;
        padding: 0 8px;
        border-radius: 3px;
        line-height: 13px;
        font-size: 8px;
      `
    },
  ];

  return (
    <ExampleContainer>
      <Carousel showThumbs={true}
                thumbWidth={40}
                showStatus={false}
                infiniteLoop={true}
                swipeable={true}
                centerMode={false}
      >
        {images.map((image, index) => {
          return (
            <div key={index} style={{padding:'0 20px'}}>
              <img style={{width: '100%'}} src={image.src} alt=""/>
              <ExampleBundle 
                customStyles={image.bundleStyles}
                bgColor={bgColor} 
                textColor={textColor}
              >
                {title ? title : 'Это пример'}
              </ExampleBundle>
            </div>
          )
        })}
      </Carousel>
    </ExampleContainer>
  );
}
