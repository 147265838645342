import {
  Avatar,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TablePagination,
  Chip,
} from "@mui/material";
import React from "react";
import {observer} from "mobx-react-lite";
import {getDeleteLabel} from "lib/userStatuses";
import {$modals} from "store/modals";
import {DELETE_USER_MODAL, EDIT_COUPON_MODAL} from "ui-components/modal/modals";
import {InfiniteScroll} from "ui-components/scrollingTable";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {$users} from "store/users/users";
import {$coupons} from "store/coupons";
import {parseUnixTimeDate} from "../../lib/features";

const columns = [
  {
    id: "code",
    label: "Код купона",
  },
  {
    id: "description",
    label: "Описание",
  },
  {
    id: "type",
    label: "Тип",
  },
  {
    id: "begin_at",
    label: "Дата начала",
  },
  {
    id: "end_at",
    label: "Дата окончания",
  },
  {
    id: "min_sum",
    label: "Мин. сумма",
  },
  {
    id: "discount",
    label: "Скидка",
  },
  {
    id: "created_by",
    label: "Создал",
  },
  {
    id: "updated_by",
    label: "Изменил",
  },
  {
    id: "created_at",
    label: "Создан",
  },
  {
    id: "updated_at",
    label: "Изменен",
  },
  {
    id: "actions",
    label: "Действия",
    align: "right",
  },
];

// Маппинг типов купонов для отображения
const couponTypeLabels = {
  products_discount: 'products_discount',
  first_purchase: 'Скидка на 1-ый заказ',
  free_delivery: 'Бесплатная доставка',
  const_discount: 'Фиксированная скидка',
  const_flexible_discount: 'Скидка на весь заказ',
  const_personal_discount: 'const_personal_discount',
};

const CouponsTable = observer(() => {
  const editCouponClicked = (coupon) => {
    $modals.show(EDIT_COUPON_MODAL, coupon);
  };

  const handleChangePage = (event, newPage) => {
    $coupons.setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    $coupons.setLimit(parseInt(event.target.value, 10));
    $coupons.setPage(1);
  };

  return (
    <>
      <InfiniteScroll onScroll={() => {
      }} hasMore={false}>

        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{minWidth: column.minWidth}}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {$coupons.list.map((coupon) => (
              <TableRow
                hover
                tabIndex={-1}
                key={coupon.id}
                sx={{backgroundColor: 'background.paper'}}
              >
                <TableCell>{coupon.code}</TableCell>
                <TableCell>{coupon.description}</TableCell>
                <TableCell>
                  <Chip
                    label={couponTypeLabels[coupon.type] || coupon.type}
                    size="small"
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>{coupon.begin_at}</TableCell>
                <TableCell>{coupon.end_at}</TableCell>
                <TableCell>{coupon.min_sum}</TableCell>
                <TableCell>{coupon.discount}</TableCell>
                <TableCell>{coupon.created_by}</TableCell>
                <TableCell>{coupon.updated_by}</TableCell>
                <TableCell>{parseUnixTimeDate(coupon.created_at)}</TableCell>
                <TableCell>{parseUnixTimeDate(coupon.updated_at)}</TableCell>
                <TableCell align="right">
                  <Tooltip title="Редактировать">
                    <IconButton onClick={() => editCouponClicked(coupon)}>
                      <EditOutlinedIcon color="info"/>
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={$coupons.totalCount}
          page={$coupons.currentPage - 1}
          onPageChange={handleChangePage}
          rowsPerPage={$coupons.limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[20, 50, 100]}
          labelRowsPerPage="Строк на странице"
        />
      </InfiniteScroll>
    </>
  );
});

export default CouponsTable;
