import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  InputAdornment,
  Grid
} from "@mui/material";
import {Modal} from "ui-components/modal";
import {$modals} from "store/modals";
import {observer} from "mobx-react-lite";
import {$bundles, bundleTypes, positions} from "store/bundles/bundles";
import {LoadingButton} from "@mui/lab";
import ColorizeIcon from '@mui/icons-material/Colorize';
import {ColorPicker} from "./components/colorPicker";
import {Controller, useForm} from "react-hook-form";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import {Example} from "./components/example";

const CreateBundleModal = observer(() => {
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: {errors},
  } = useForm({
    defaultValues: {
      title: '',
      text_color: '#FFFFFF',
      bg_color: '#000000',
      expired_at: '',
      url: '',
      promo_type: 'info',
      position: 'bottom',
      is_active: true,
      is_visible: true,
      count_need_to_pay: '',
      count_with_discount: '',
      discount_percent: '',
    },
  });

  const [btnLoading, setBtnLoading] = React.useState(false);
  const [colorPicker, setColorPicker] = React.useState(null);
  const selectedType = watch('promo_type');

  const onSubmit = async (data) => {
    setBtnLoading(true);

    try {
      const submissionData = {
        ...data,
        promo_type: data.promo_type === 'info' ? null : data.promo_type,
      };

      await $bundles.create(submissionData);
      $modals.close();
    } catch (err) {
      if (err.response?.data && err.response.status === 422) {
        const errorResponse = err.response.data.errors;
        for (const key in errorResponse) {
          setError(key, {type: 'manual', message: errorResponse[key][0]});
        }
      }
    } finally {
      setBtnLoading(false);
    }
  };

  const setColorClicked = (type) => {
    setColorPicker(type === colorPicker ? null : type);
  };

  const onChangeColor = (value) => {
    if (colorPicker === 'text') {
      setValue('text_color', value);
    } else {
      setValue('bg_color', value);
    }
  };

  return (
    <Modal title="Создать бандл" lgWidth>
      <Grid container spacing={5}>
        <Grid item xs={12} md={7}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="title"
              control={control}
              rules={{required: "Название обязательно"}}
              render={({field}) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  sx={{mb: 3}}
                  label="Название"
                  variant="outlined"
                  error={!!errors.title}
                  helperText={errors.title?.message}
                />
              )}
            />

            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Controller
                  name="text_color"
                  control={control}
                  render={({field}) => (
                    <TextField
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment onClick={() => setColorClicked('text')} sx={{cursor: 'pointer'}}
                                          position="end">
                            <ColorizeIcon sx={{color: colorPicker === 'text' ? '#067ed5' : '#101010'}}/>
                          </InputAdornment>
                        ),
                      }}
                      required
                      fullWidth
                      sx={{mb: 3}}
                      label="Цвет (пример #FFFFFF)"
                      variant="outlined"
                      error={!!errors.text_color}
                      helperText={errors.text_color?.message}
                      InputLabelProps={{shrink: true}}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Controller
                  name="bg_color"
                  control={control}
                  render={({field}) => (
                    <TextField
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment onClick={() => setColorClicked('bg')} sx={{cursor: 'pointer'}} position="end">
                            <FormatColorFillIcon sx={{color: colorPicker === 'bg' ? '#067ed5' : '#101010'}}/>
                          </InputAdornment>
                        ),
                      }}
                      required
                      fullWidth
                      sx={{mb: 3}}
                      label="Фон (пример #000000)"
                      variant="outlined"
                      error={!!errors.bg_color}
                      helperText={errors.bg_color?.message}
                      InputLabelProps={{shrink: true}}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Controller
              name="position"
              control={control}
              render={({field}) => (
                <TextField
                  sx={{mb: 3}}
                  {...field}
                  required
                  select
                  fullWidth
                  label="Положение"
                  variant="outlined"
                >
                  {positions.map((el) => (
                    <MenuItem
                      disabled={el.disabled}
                      value={el.value}
                      key={el.value}
                    >
                      {el.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            {colorPicker && (
              <ColorPicker
                title={watch('title')}
                type={colorPicker}
                textColor={watch('text_color')}
                bgColor={watch('bg_color')}
                setColor={onChangeColor}
              />
            )}

            <Controller
              name="expired_at"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  fullWidth
                  sx={{mb: 3}}
                  label="Дата окончания (не обязательное поле)"
                  variant="outlined"
                  type="date"
                  error={!!errors.expired_at}
                  helperText={errors.expired_at?.message}
                  InputLabelProps={{shrink: true}}
                />
              )}
            />
            <Controller
              name="url"
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  fullWidth
                  sx={{mb: 3}}
                  label="Ссылка на правила"
                  variant="outlined"
                  error={!!errors.url}
                  helperText={errors.url?.message}
                />
              )}
            />

            <Controller
              name="promo_type"
              control={control}
              render={({field}) => (
                <TextField
                  sx={{mb: 3}}
                  {...field}
                  required
                  select
                  fullWidth
                  label="Тип"
                  variant="outlined"
                >
                  {bundleTypes.map((el) => (
                    <MenuItem
                      disabled={el.disabled}
                      value={el.value}
                      key={el.value}
                    >
                      {el.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            {selectedType === 5 && (
              <Grid container spacing={2} sx={{mb: 3}}>
                <Grid item md={4} xs={12}>
                  <Controller
                    name="count_need_to_pay"
                    control={control}
                    rules={{required: "Это поле обязательно"}}
                    render={({field}) => (
                      <TextField
                        {...field}
                        fullWidth
                        required
                        type="number"
                        label="Кол-во для оплаты"
                        variant="outlined"
                        error={!!errors.count_need_to_pay}
                        helperText={errors.count_need_to_pay?.message}
                        InputLabelProps={{shrink: true}}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    name="count_with_discount"
                    control={control}
                    rules={{required: "Это поле обязательно"}}
                    render={({field}) => (
                      <TextField
                        {...field}
                        fullWidth
                        required
                        type="number"
                        label="Кол-во со скидкой"
                        variant="outlined"
                        error={!!errors.count_with_discount}
                        helperText={errors.count_with_discount?.message}
                        InputLabelProps={{shrink: true}}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Controller
                    name="discount_percent"
                    control={control}
                    rules={{required: "Это поле обязательно"}}
                    render={({field}) => (
                      <TextField
                        {...field}
                        fullWidth
                        required
                        type="number"
                        label="Скидка (%)"
                        variant="outlined"
                        error={!!errors.discount_percent}
                        helperText={errors.discount_percent?.message}
                        InputLabelProps={{shrink: true}}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}

            <Box sx={{display: "flex"}}>

              <Controller
                name="is_active"
                control={control}
                render={({field}) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value}/>}
                    label="Активность"
                  />
                )}
              />
              <Controller
                name="is_visible"
                control={control}
                render={({field}) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value}/>}
                    label="Видимость"
                  />
                )}
              />

              <LoadingButton sx={{ml:'auto'}} type="submit" variant="contained" loading={btnLoading}>
                Сохранить
              </LoadingButton>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} md={5}>
          <Example title={watch('title')} bgColor={watch('bg_color')} textColor={watch('text_color')}/>
        </Grid>
      </Grid>
    </Modal>
  );
});

export default CreateBundleModal;
