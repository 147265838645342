import React from "react";
import { Box } from "@mui/material";
import { Modal } from "ui-components/modal";
import { useState } from "react";
import { $modals } from "store/modals";
import { observer } from "mobx-react-lite";
import { $products } from "store/bundles/productsInBundle";
import { LoadingButton } from "@mui/lab";

const DeleteProductFromBundleModal = observer(() => {
  const [btnLoading, setBtnLoading] = useState(false);
  const product = $modals.params;

  console.log('product', product);

  const onSubmit = () => {
    setBtnLoading(true);
    $products
      .deleteProductFromBundle(product.product_id)
      .then(() => $modals.close())
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal
      title="Удаление товара"
      subheader={
        <>
          Вы действительно хотите удалить товар из бандла <br />{" "}
          <b>{product.product.name}</b>?
        </>
      }
    >
      <Box sx={{ textAlign: "right" }}>
        <LoadingButton
          loading={btnLoading}
          variant="contained"
          onClick={onSubmit}
        >
          Удалить
        </LoadingButton>
      </Box>
    </Modal>
  );
});

export default DeleteProductFromBundleModal;
