import React from "react";
import { Button } from "@mui/material";
import interpolate from "interpolate";

const notificationTypes = {
  recommended: {
    text: "Посмотреть рекомендованные товары",
    link: "https://tovarbezpereplat.ru/products?type=tag&query={id}",
  },
  order: {
    text: "Перейти в заказ",
    link: "https://tovarbezpereplat.ru/orders/{id}",
  },
  product: {
    text: "Посмотреть товар",
    link: "https://tovarbezpereplat.ru/products/{id}",
  },
  category: {
    text: "Посмотреть категорию товаров",
    link: "https://tovarbezpereplat.ru/products?type=category&query={id}",
  },
  promo_catalog: {
    text: "Посмотреть каталог товаров",
    link: "https://tovarbezpereplat.ru/promo/products?network_id={id}",
  },
  promo: {
  }
};

const renderNotification = (notification) => {
  if (notification.model_type && notification.model_type !== 'promo') {
    return interpolate(notificationTypes[notification.model_type].link, {
      id: notification.model_id,
    });
  } else {
    return null;
  }
};

const renderText = (notification) => {
  if (notification.model_type) {
    return notificationTypes[notification.model_type].text;
  } else {
    return null;
  }
};

export const NotificationMessage = ({ notification }) => {
  return (
    <div>
      {notification.title && (
        <div style={{ fontSize: "16px" }}>{notification.title}</div>
      )}
      {notification.body && (
        <div style={{ fontSize: "16px" }}>{notification.body}</div>
      )}

      {!!notification.model_type && notification.model_type !== "order" && notification.model_type !== "promo" && (
        <Button
          sx={{ mt: 1 }}
          size="small"
          onClick={(e) => e.stopPropagation()}
          variant="contained"
          color="secondary"
          target="_blank"
          href={renderNotification(notification)}
        >
          {renderText(notification)}
        </Button>
      )}
    </div>
  );
};
