import React from "react";
import {Box, Button, Typography} from "@mui/material";
import {Modal} from "ui-components/modal";
import {$modals} from "store/modals";
import {observer} from "mobx-react-lite";
import {LoadingButton} from "@mui/lab";
import {$bundles} from "../../../store/bundles/bundles";

const DeleteBundleModal = observer(() => {
  const bundle = $modals.params;
  const [loading, setLoading] = React.useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await $bundles.deleteBundle(bundle.id);
      $modals.close();
    } catch (error) {
      console.error('Error deleting bundle:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal title="Удалить бандл">
      <Typography sx={{mb: 3}}>
        Вы действительно хотите удалить бандл "{bundle.title}"?
      </Typography>

      <Box sx={{display: "flex", justifyContent: "flex-end", gap: 2}}>
        <Button onClick={() => $modals.close()}>
          Отмена
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          loading={loading}
          onClick={handleDelete}
        >
          Удалить
        </LoadingButton>
      </Box>
    </Modal>
  );
});

export default DeleteBundleModal; 