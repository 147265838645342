import React, { useState } from "react";
import {
  FormControl,
  FormHelperText, InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import { Modal } from "ui-components/modal";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";
import { $modals } from "store/modals";
import { $roles } from "store/roles/roles";
import { $coupons, couponTypes } from "store/coupons";

const EditCouponModal = observer(() => {
  const coupon = $modals.params;

  console.log(coupon);
  const [code, setCode] = useState(coupon.code || "");
  const [description, setDescription] = useState(coupon.description || "");
  const [type, setType] = useState(coupon.type || "");
  const [beginAt, setBeginAt] = useState(coupon.begin_at ? coupon.begin_at.split('T')[0] : "");
  const [endAt, setEndAt] = useState(coupon.end_at ? coupon.end_at.split('T')[0] : "");
  const [minSum, setMinSum] = useState(coupon.min_sum || "");
  const [discount, setDiscount] = useState(coupon.discount || "");
  const [oncePerUser, setOncePerUser] = useState(coupon.once_per_user || false);
  const [firstOrderOnly, setFirstOrderOnly] = useState(coupon.first_order_only || false);
  const [errors, setErrors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    $roles.fetchRoles({ hideSpinner: true });
  }, []);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}.${month}.${year}`;
  };

  const getAlertText = () => {
    if (!type || !minSum || !discount || !beginAt || !endAt) return "";

    const sumText = `При создании заказа на сумму ${minSum} ₽`;
    const discountText = type === 'const_discount' 
      ? `фиксированная скидка на заказ в размере ${discount} ₽`
      : `скидка на заказ в размере ${discount} %`;
    const periodText = `Купон действует с ${formatDate(beginAt)} по ${formatDate(endAt)}`;
    const usageText = oncePerUser ? 'Купон можно применить только 1 раз' : 'Купон многоразовый';
    const firstOrderText = firstOrderOnly ? 'Купон действует только на первый заказ' : '';

    return `${sumText} ${discountText}. ${periodText}. ${usageText}${firstOrderOnly ? '. ' + firstOrderText : ''}.`;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    setErrors({});

    $coupons.edit({
      id: coupon.id,
      code,
      description,
      type,
      begin_at: beginAt,
      end_at: endAt,
      min_sum: Number(minSum),
      discount: Number(discount),
      once_per_user: oncePerUser,
      first_order_only: firstOrderOnly,
    })
      .then(() => {
        $coupons.fetchList();
        $modals.close();
      })
      .catch((err) => {
        if (err.response?.data && err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      })
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal title="Редактирование купона">
      <form onSubmit={onSubmit}>
        <TextField
          error={!!errors.code}
          helperText={errors.code}
          required
          value={code}
          onChange={(e) => setCode(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Код купона"
          variant="outlined"
        />

        <TextField
          error={!!errors.description}
          helperText={errors.description}
          required
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Описание"
          variant="outlined"
          multiline
          rows={2}
        />

        <FormControl sx={{ mb: 3 }} error={!!errors.type} fullWidth>
          <InputLabel required>Тип купона</InputLabel>
          <Select
            value={type}
            onChange={(e) => setType(e.target.value)}
            label="Тип купона"
            error={!!errors.type}
          >
            {couponTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.title}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.type}</FormHelperText>
        </FormControl>

        <TextField
          error={!!errors.begin_at}
          helperText={errors.begin_at}
          required
          value={beginAt}
          onChange={(e) => setBeginAt(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Дата начала"
          variant="outlined"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          error={!!errors.end_at}
          helperText={errors.end_at}
          required
          value={endAt}
          onChange={(e) => setEndAt(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Дата окончания"
          variant="outlined"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          error={!!errors.min_sum}
          helperText={errors.min_sum}
          required
          value={minSum}
          onChange={(e) => setMinSum(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Минимальная сумма"
          variant="outlined"
          type="number"
        />

        <TextField
          error={!!errors.discount}
          helperText={errors.discount}
          required
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Размер скидки"
          variant="outlined"
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span style={{color:'#000'}}>{type === 'const_discount' ? '₽' : '%' } </span>
              </InputAdornment>
            ),
          }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={oncePerUser}
              onChange={(e) => setOncePerUser(e.target.checked)}
            />
          }
          label="Купон можно использовать только один раз"
          sx={{ mb: 1, display: 'block' }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={firstOrderOnly}
              onChange={(e) => setFirstOrderOnly(e.target.checked)}
            />
          }
          label="Купон только для первого заказа"
          sx={{ mb: 1, display: 'block' }}
        />

        {getAlertText() && (
          <Alert severity="info" sx={{ mb: 3 }}>
            {getAlertText()}
          </Alert>
        )}

        <LoadingButton
          sx={{ float: "right" }}
          type="submit"
          loading={btnLoading}
          variant="contained"
        >
          Сохранить
        </LoadingButton>
      </form>
    </Modal>
  );
});

export default EditCouponModal;
