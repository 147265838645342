import React from "react";
import {Box, Checkbox, FormControlLabel, Grid, InputAdornment, MenuItem, TextField} from "@mui/material";
import {Modal} from "ui-components/modal";
import {Controller, useForm} from 'react-hook-form';
import {$modals} from "store/modals";
import {observer} from "mobx-react-lite";
import {LoadingButton} from "@mui/lab";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {$bundles, bundleTypes, positions} from "../../../store/bundles/bundles";
import {ColorPicker} from "./components/colorPicker";
import ColorizeIcon from "@mui/icons-material/Colorize";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import {$products} from "../../../store/bundles/productsInBundle";
import {Example} from "./components/example";

const ColorFields = ({ control, errors, colorPicker, setColorClicked }) => (
  <Grid container spacing={2}>
    <Grid item md={6} xs={12}>
      <Controller
        name="text_color"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            InputProps={{
              endAdornment: (
                <InputAdornment onClick={() => setColorClicked('text')} sx={{ cursor: 'pointer' }} position="end">
                  <ColorizeIcon sx={{ color: colorPicker === 'text' ? '#067ed5' : '#101010' }} />
                </InputAdornment>
              ),
            }}
            required
            fullWidth
            sx={{ mb: 3 }}
            label="Цвет (пример #FFFFFF)"
            variant="outlined"
            error={!!errors.text_color}
            helperText={errors.text_color?.message}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </Grid>
    <Grid item md={6} xs={12}>
      <Controller
        name="bg_color"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            InputProps={{
              endAdornment: (
                <InputAdornment onClick={() => setColorClicked('bg')} sx={{ cursor: 'pointer' }} position="end">
                  <FormatColorFillIcon sx={{ color: colorPicker === 'bg' ? '#067ed5' : '#101010' }} />
                </InputAdornment>
              ),
            }}
            required
            fullWidth
            sx={{ mb: 3 }}
            label="Фон (пример #000000)"
            variant="outlined"
            error={!!errors.bg_color}
            helperText={errors.bg_color?.message}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </Grid>
  </Grid>
);

const PromoFields = ({ control, errors }) => (
  <Grid container spacing={2} sx={{ mb: 3 }}>
    <Grid item md={4} xs={12}>
      <Controller
        name="count_need_to_pay"
        control={control}
        rules={{ required: "Это поле обязательно" }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            required
            type="number"
            label="Кол-во для оплаты"
            variant="outlined"
            error={!!errors.count_need_to_pay}
            helperText={errors.count_need_to_pay?.message}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </Grid>
    <Grid item md={4} xs={12}>
      <Controller
        name="count_with_discount"
        control={control}
        rules={{ required: "Это поле обязательно" }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            required
            type="number"
            label="Кол-во со скидкой"
            variant="outlined"
            error={!!errors.count_with_discount}
            helperText={errors.count_with_discount?.message}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </Grid>
    <Grid item md={4} xs={12}>
      <Controller
        name="discount_percent"
        control={control}
        rules={{ required: "Это поле обязательно" }}
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            required
            type="number"
            label="Скидка (%)"
            variant="outlined"
            error={!!errors.discount_percent}
            helperText={errors.discount_percent?.message}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </Grid>
  </Grid>
);

const EditBundleModal = observer(() => {
  const defaultValues = {
    title: $modals.params.title,
    text_color: $modals.params.text_color || '',
    bg_color: $modals.params.bg_color || '',
    expired_at: $modals.params.expired_at,
    url: $modals.params.url || '',
    promo_type: $modals.params.promo_type === null ? 'info' : $modals.params.promo_type,
    is_active: !!$modals.params.is_active,
    is_visible: !!$modals.params.is_visible,
    count_need_to_pay: $modals.params.count_need_to_pay || '',
    count_with_discount: $modals.params.count_with_discount || '',
    discount_percent: $modals.params.discount_percent || '',
    position: $modals.params.position || 'bottom',
  };

  const {
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm({ defaultValues });

  const [btnLoading, setBtnLoading] = React.useState(false);
  const [colorPicker, setColorPicker] = React.useState(null);
  const selectedType = watch('promo_type');

  const handleDelete = async () => {
    if(!window.confirm('Удалить?')) return;
    
    setBtnLoading(true);
    try {
      $products.setCurrentBundle({});
      $products.setProductsInBundleList([]);
      await $bundles.delete($modals.params.id);
      $modals.close();
    } catch (error) {
      console.error('Delete bundle error:', error);
    } finally {
      setBtnLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setBtnLoading(true);
    try {
      const submissionData = {
        ...data,
        promo_type: data.promo_type === 'info' ? null : data.promo_type,
      };

      await $bundles.update($modals.params.id, submissionData);
      await $bundles.fetchBundles();
      $modals.close();
    } catch (err) {
      if (err.response?.data && err.response.status === 422) {
        const errorResponse = err.response.data.errors;
        Object.entries(errorResponse).forEach(([key, [message]]) => {
          setError(key, { type: 'manual', message });
        });
      }
    } finally {
      setBtnLoading(false);
    }
  };

  const setColorClicked = (type) => {
    setColorPicker(type === colorPicker ? null : type);
  };

  const onChangeColor = (value) => {
    setValue(colorPicker === 'text' ? 'text_color' : 'bg_color', value);
  };

  return (
    <Modal title="Редактировать бандл" lgWidth>
      <Grid container spacing={5}>
        <Grid item xs={12} md={7}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="title"
              control={control}
              rules={{ required: "Название обязательно" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Название"
                  variant="outlined"
                  error={!!errors.title}
                  helperText={errors.title?.message}
                />
              )}
            />

            <ColorFields 
              control={control}
              errors={errors}
              colorPicker={colorPicker}
              setColorClicked={setColorClicked}
            />

            <Controller
              name="position"
              control={control}
              render={({field}) => (
                <TextField
                  sx={{mb: 3}}
                  {...field}
                  required
                  select
                  fullWidth
                  label="Положение"
                  variant="outlined"
                >
                  {positions.map((el) => (
                    <MenuItem
                      disabled={el.disabled}
                      value={el.value}
                      key={el.value}
                    >
                      {el.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            {colorPicker && (
              <ColorPicker
                title={watch('title')}
                type={colorPicker}
                textColor={watch('text_color')}
                bgColor={watch('bg_color')}
                setColor={onChangeColor}
              />
            )}

            <Controller
              name="expired_at"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Дата окончания (не обязательное поле)"
                  variant="outlined"
                  type="date"
                  error={!!errors.expired_at}
                  helperText={errors.expired_at?.message}
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />

            <Controller
              name="url"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  sx={{ mb: 3 }}
                  label="Ссылка на правила"
                  variant="outlined"
                  error={!!errors.url}
                  helperText={errors.url?.message}
                />
              )}
            />

            <Controller
              name="promo_type"
              control={control}
              render={({ field }) => (
                <TextField
                  sx={{ mb: 3 }}
                  {...field}
                  required
                  select
                  fullWidth
                  label="Тип"
                  variant="outlined"
                >
                  {bundleTypes.map((el) => (
                    <MenuItem 
                      disabled={el.disabled} 
                      value={el.value} 
                      key={el.value}
                    >
                      {el.title}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            {selectedType === 5 && <PromoFields control={control} errors={errors} />}

            <Box sx={{ mb: 3 }}>
              <Controller
                name="is_active"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Активность"
                  />
                )}
              />
              <Controller
                name="is_visible"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Видимость"
                  />
                )}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <LoadingButton
                sx={{ mr: 3 }}
                variant="contained"
                color="error"
                disabled={btnLoading}
                startIcon={<DeleteOutlineIcon />}
                onClick={handleDelete}
              >
                Удалить
              </LoadingButton>
              <LoadingButton
                variant="contained"
                type="submit"
                disabled={btnLoading}
              >
                Сохранить
              </LoadingButton>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} md={5}>
          <Example title={watch('title')} bgColor={watch('bg_color')} textColor={watch('text_color')}/>
        </Grid>
      </Grid>
    </Modal>
  );
});

export default EditBundleModal;