import { types } from "mobx-state-tree";
import { flow } from "mobx";
import { api, v3Endpoint } from "../../api";
import { union } from "lodash";
import { $chatMessages } from "./messages";
import {$textarea} from "./textarea";

export const $chatRooms = types
  .model({
    showUserInfo: types.optional(types.boolean, false),
    selectedCustomerId: types.maybeNull(types.number, null),
    rooms: types.frozen([]),
    filter: types.optional(types.string, "all"),
    showSearch: types.optional(types.boolean, false),
    searchResultRooms: types.frozen(null),
    limit: types.optional(types.number, 20),
    offset: types.optional(types.number, 0),
    hasMore: types.optional(types.boolean, false),
    loading: types.optional(types.boolean, false),
    importantCount: types.frozen(0),
  })
  .views((self) => ({
    get userDraft () {
      if(self.selectedCustomerId) {
        console.log(self.selectedCustomerId);
        const room = self.rooms.find(el => el.message.customer_id == self.selectedCustomerId)
        console.log(room);
        return room ? room.draft  : ''
      }
      return '';
    }
  }))
  .actions((self) => ({
    fetchRooms: flow(function* (params = {}) {
      if (!params.paginate) {
        self.resetPaginate();
      }

      try {
        if (!params.hiddenLoader) {
          self.setLoading(true);
        }
        const res = yield api(v3Endpoint)
          .post("consultant/rooms", {
            offset: self.offset,
            limit: self.limit,
            filter: self.filter,
            ...(params.search && { search: params.search }),
          })
          .then((res) => res.data);

        const rooms = res.rooms;

        self.setImportantCount(res.important_count);
        self.setHasMore(rooms.length >= self.limit);

        if (self.showSearch && !params.fromPusher) {
          self.setSearchResultRooms(rooms);
        } else {
          self.setRooms(params.paginate ? union(self.rooms, rooms) : rooms);
        }

        // $textarea.setValue($chatRooms.userDraft);

      } catch (err) {
        throw err;
      } finally {
        self.setLoading(false);
      }
    }),
    setRooms(value) {
      self.rooms = value;
    },
    toggleShowUserInfo() {
      self.showUserInfo = !self.showUserInfo;
    },
    setSelectedCustomerId(id) {
      self.selectedCustomerId = id;
      self.showUserInfo = false;
      //Если передали не null
      if (self.selectedCustomerId) {
        $chatMessages.getMessagesByUser(self.selectedCustomerId);
      }
    },
    updateRoomProperty(property, value, selectedCustomerId) {
      const updatedRooms = [...self.rooms];
      const index = updatedRooms.findIndex((el) => el.message.user.id === selectedCustomerId);

      if (index !== -1) {
        updatedRooms[index] = { ...updatedRooms[index], [property]: value };
        $chatRooms.setRooms(updatedRooms);
      }
    },
    setSearchResultRooms(value) {
      self.searchResultRooms = value;
    },
    paginate() {
      self.offset += self.limit;
      self.fetchRooms({ paginate: true });
    },
    resetPaginate() {
      self.offset = 0;
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    setLoading(value) {
      self.loading = value;
    },
    setFilter(value) {
      self.filter = value;
      self.fetchRooms();
    },
    setShowSearch(value) {
      self.showSearch = value;
    },
    setImportantCount(value) {
      self.importantCount = value;
    },
  }))
  .create();
