import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Box, Button, Stack, TextField, MenuItem } from "@mui/material";
import CouponsTable from "./table";
import { debounce } from "lodash";
import { InputSearch } from "ui-components/inputSearch";
import { $modals } from "store/modals";
import { CREATE_COUPON_MODAL } from "ui-components/modal/modals";
import { isMobile } from "react-device-detect";
import { $sidebar } from "store/sidebar";
import { $coupons, couponTypes } from "store/coupons";

const Coupons = observer(() => {
  useEffect(() => {
    $sidebar.setPageTitle("Купоны");
    $coupons.fetchList();
  }, []);

  const handleSearch = debounce((e) => {
    $coupons.setSearchValue(e.target.value);
  }, 500);

  const handleTypeChange = (e) => {
    $coupons.setSelectedType(e.target.value);
  };

  const createCouponButton = (
    <Box sx={{ padding: isMobile ? "0 0 12px 0" : "7px 0 0 0" }}>
      <Button variant="contained" onClick={() => $modals.show(CREATE_COUPON_MODAL)}>
        Создать купон
      </Button>
    </Box>
  );

  return (
    <>
      {isMobile && createCouponButton}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Stack spacing={2} direction="row">
          <InputSearch
            onChange={handleSearch}
            placeholder="Поиск по коду купона"
          />
          <TextField
            select
            size="small"
            sx={{
              minWidth: 200,
              '& .MuiOutlinedInput-root': {
                height: '50px',
                backgroundColor: '#fff',
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'transparent',
                },
                '.MuiSelect-select': {
                  background:'#fff'
                }
              },
              '& .MuiInputLabel-root': {
                color: '#000',
              },
            }}
            value={$coupons.selectedType || ''}
            onChange={handleTypeChange}
            SelectProps={{
              displayEmpty: true,
              renderValue: (value) => {
                if (!value) return "Все типы";
                const selectedType = couponTypes.find(type => type.value === value);
                return selectedType ? selectedType.title : "Все типы";
              },
            }}
          >
            <MenuItem value="">
              Все типы
            </MenuItem>
            {couponTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.title}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        {!isMobile && createCouponButton}
      </Box>
      <CouponsTable />
    </>
  );
});

export default Coupons;
