import React from "react";
import {
  Box,
  Alert,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import {Modal} from "ui-components/modal";
import {useState} from "react";
import {$products} from "store/bundles/productsInBundle";
import {$modals} from "store/modals";
import {observer} from "mobx-react-lite";
import {LoadingButton} from "@mui/lab";

const UploadTxtToBundleModal = observer(() => {

  const [file, setFile] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [error, setError] = useState(null);
  const [overwrite, setOverwrite] = useState(false);

  const onSubmit = () => {
    setBtnLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('file', file);

    $products.uploadBarcodesFile(file, overwrite)
      .then(() => {
        $modals.close()
      })
      .catch(err => {
        if (err.response) {
          setError(err.response.data.message)
        }
      })
      .finally(() => setBtnLoading(false))
  }

  return (
    <Modal title="Загрузить файл с штрих-кодами" subheader={
      <>
        Выберите файл <b>.txt</b>, каждый код должен начинаться с новой строки.
      </>
    }>
      {error && <Alert sx={{mb: 3}} severity="error">{error}</Alert>}
      <Box sx={{ mb: 2 }}>
        <input
          type="file"
          accept=".txt"
          onChange={e => setFile(e.target.files[0])}
        />
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={overwrite}
            onChange={(e) => setOverwrite(e.target.checked)}
          />
        }
        label="Перезаписать существующие штрих-коды"
      />
      <Box sx={{textAlign: 'right', mt: 3}}>
        <LoadingButton
          variant="contained"
          onClick={onSubmit}
          loading={btnLoading}
        >
          Загрузить
        </LoadingButton>
      </Box>
    </Modal>
  )
});

export default UploadTxtToBundleModal;