import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Alert,
} from "@mui/material";
import { Modal } from "ui-components/modal";
import { observer } from "mobx-react-lite";
import { LoadingButton } from "@mui/lab";
import styled from "@emotion/styled";
import { $modals } from "store/modals";
import { $coupons, couponTypes } from "store/coupons";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";

export const SelectSt = styled(Select)`
  .MuiSelect-select {
    padding: 10px;
  }

  #roles-select {
    min-height: 32px;
  }
`;

const CreateCouponModal = observer(() => {
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [beginAt, setBeginAt] = useState("");
  const [endAt, setEndAt] = useState("");
  const [minSum, setMinSum] = useState("");
  const [discount, setDiscount] = useState("");
  const [oncePerUser, setOncePerUser] = useState(false);
  const [firstOrderOnly, setFirstOrderOnly] = useState(false);
  const [errors, setErrors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}.${month}.${year}`;
  };

  const getAlertText = () => {
    if (!type || !minSum || !discount || !beginAt || !endAt) return "";

    const sumText = `При создании заказа на сумму ${minSum} ₽`;
    const discountText = type === 'const_discount' 
      ? `фиксированная скидка на заказ в размере ${discount} ₽`
      : `скидка на заказ в размере ${discount} %`;
    const periodText = `Купон действует с ${formatDate(beginAt)} по ${formatDate(endAt)}`;
    const usageText = oncePerUser ? 'Купон можно применить только 1 раз' : 'Купон многоразовый';
    const firstOrderText = firstOrderOnly ? 'Купон действует только на первый заказ' : '';

    return `${sumText} ${discountText}. ${periodText}. ${usageText}${firstOrderOnly ? '. ' + firstOrderText : ''}.`;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setBtnLoading(true);
    setErrors({});

    $coupons.create({
      code,
      description,
      type,
      begin_at: beginAt,
      end_at: endAt,
      min_sum: Number(minSum),
      discount: Number(discount),
      once_per_user: oncePerUser,
      first_order_only: firstOrderOnly,
    })
      .then(() => {
        $coupons.fetchList();
        $modals.close();
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response?.data && err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      })
      .finally(() => setBtnLoading(false));
  };

  return (
    <Modal title="Создание купона">
      <form onSubmit={onSubmit}>
        <TextField
          error={!!errors.code}
          helperText={errors.code}
          required
          onChange={(e) => setCode(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Код купона"
          variant="outlined"
        />

        <TextField
          error={!!errors.description}
          helperText={errors.description}
          required
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Описание"
          variant="outlined"
          multiline
          rows={2}
        />

        <FormControl sx={{ mb: 3 }} error={!!errors.type} fullWidth>
          <InputLabel required>Тип купона</InputLabel>
          <Select
            value={type}
            onChange={(e) => setType(e.target.value)}
            label="Тип купона"
            error={!!errors.type}
          >
            {couponTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.title}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.type}</FormHelperText>
        </FormControl>

        <TextField
          error={!!errors.begin_at}
          helperText={errors.begin_at}
          required
          onChange={(e) => setBeginAt(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Дата начала"
          variant="outlined"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          error={!!errors.end_at}
          helperText={errors.end_at}
          required
          onChange={(e) => setEndAt(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Дата окончания"
          variant="outlined"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          error={!!errors.min_sum}
          helperText={errors.min_sum}
          required
          onChange={(e) => setMinSum(e.target.value)}
          fullWidth
          sx={{ mb: 3 }}
          label="Минимальная сумма"
          variant="outlined"
          type="number"
        />

        <TextField
          error={!!errors.discount}
          helperText={errors.discount}
          required
          onChange={(e) => setDiscount(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
          label="Размер скидки"
          variant="outlined"
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span style={{color:'#000'}}>{type === 'const_discount' ? '₽' : '%'}</span>
              </InputAdornment>
            ),
          }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={oncePerUser}
              onChange={(e) => setOncePerUser(e.target.checked)}
            />
          }
          label="Купон можно использовать только один раз"
          sx={{ mb: 1, display: 'block' }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={firstOrderOnly}
              onChange={(e) => setFirstOrderOnly(e.target.checked)}
            />
          }
          label="Купон только для первого заказа"
          sx={{ mb: 1, display: 'block' }}
        />

        {getAlertText() && (
          <Alert severity="info" sx={{ mb: 3 }}>
            {getAlertText()}
          </Alert>
        )}

        <LoadingButton
          sx={{ float: "right" }}
          type="submit"
          loading={btnLoading}
          variant="contained"
        >
          Создать
        </LoadingButton>
      </form>
    </Modal>
  );
});

export default CreateCouponModal;
