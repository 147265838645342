import {types} from "mobx-state-tree";
import {api, v1Endpoint, v3Endpoint} from "api";
import {flow} from "mobx";
import {$spinner} from "../spinner";
import {$modals} from "../modals";
import {RECEIPT_DETAILS_MODAL} from "ui-components/modal/modals";

export const $transactions = types
  .model("Transaction", {
    list: types.frozen([]),
    searchValue: types.frozen(null),
    transactionType: types.frozen(null),
    hasOfdError: types.frozen(false),
    hasMarkedProduct: types.frozen(false),
    limit: types.optional(types.number, 50),
    offset: types.optional(types.number, 0),
    totalCount: types.optional(types.number, 0),
    currentPage: types.optional(types.number, 1),
  })
  .actions((self) => ({
    fetchList: flow(function* () {
      try {
        $spinner.show();
        const res = yield api(v1Endpoint)
          .get(`transactions`, {
            params: {
              search: self.searchValue,
              limit: self.limit,
              offset: self.offset,
              transaction_type: self.transactionType,
              ofd_error: self.hasOfdError,
              has_marked_product: self.hasMarkedProduct
            },
          })
          .then((res) => res.data);

        self.setTransactions(res.transactions);
        self.setTotalCount(res.total_count);
      } catch (err) {
        throw err;
      } finally {
        $spinner.hide();
      }
    }),
    
    // Новый метод для проверки чека через 1-ОФД
    check1OfdReceipt: flow(function* (params) {
      try {
        const response = yield api(v3Endpoint)
          .get(`check-ofd-receipt`, {
            params: {
              t: params.t,
              s: params.s,
              fn: params.fn,
              i: params.i,
              fp: params.fp,
              n: params.n || 1
            }
          })
          .then((res) => res.data);
          
        return response.exists;
      } catch (err) {
        console.error('Ошибка при проверке чека 1-ОФД:', err);
        return false;
      }
    }),
    
    checkOfdReceipt: flow(function* (receiptId) {
      try {
        const res = yield api(v1Endpoint)
          .get(`transactions/check-receipt?receipt_id=${receiptId}`)
          .then((res) => res.data);
        $modals.show(RECEIPT_DETAILS_MODAL, res);
      } catch (err) {
        $spinner.hide();
        throw err;
      }
    }),
    sendOfdReceipt: flow(function* (receiptId) {
      try {
        const res = yield api(v1Endpoint)
          .post(`transactions/send-receipt?receipt_id=${receiptId}`)
          .then((res) => res.data);
        $modals.show(RECEIPT_DETAILS_MODAL, res);
      } catch (err) {
        $spinner.hide();
        throw err;
      }
    }),
    createTransaction: flow(function* ({orderId, productId, transactionType, paymentId}) {
      try {
        yield api(v1Endpoint)
          .post(`transactions`, {
            order_id: orderId,
            product_id: productId,
            transaction_type: transactionType,
            payment_id: paymentId
          })
          .then((res) => res.data);
      } catch (err) {
        throw err;
      }
    }),
    setTransactions(data) {
      self.list = data;
    },
    setPage(page) {
      self.offset = (page - 1) * self.limit;
      self.currentPage = page;
      self.fetchList();
    },
    setSearchValue(value) {
      self.offset = 0;
      self.searchValue = value;
      self.fetchList();
    },
    setTransactionType(type) {
      self.offset = 0;
      self.transactionType = !!type ? type : null;
      self.fetchList();
    },
    setTotalCount(value) {
      self.totalCount = value;
    },
    setHasOfdError(value) {
      self.hasOfdError = value;
      self.fetchList();
    },
    setHasMarkedProduct(value) {
      self.hasMarkedProduct = value;
      self.fetchList();
    },
  }))
  .create();
