import { types } from "mobx-state-tree";
import { api, v3Endpoint } from "api";
import { flow } from "mobx";
import { $spinner } from "./spinner";
import {$products} from "./bundles/productsInBundle";
import iziToast from "izitoast";

export const $coupons = types
  .model("Coupon", {
    list: types.frozen([]),
    searchValue: types.optional(types.string, ""),
    selectedType: types.optional(types.maybeNull(types.string), null),
    limit: types.optional(types.number, 20),
    currentPage: types.optional(types.number, 1),
    totalCount: types.optional(types.number, 0),
  })
  .actions((self) => ({
    fetchList: flow(function* () {
      try {
        $spinner.show();
        const response = yield api(v3Endpoint)
          .get(`coupons`, {
            params: {
              code: self.searchValue,
              type: self.selectedType,
              page: self.currentPage,
              per_page: self.limit,
            },
          })
          .then((res) => res.data);

        if (response.status === 'success' && response.data) {
          self.setList(response.data.data);
          self.setTotalCount(response.data.total);
          self.setCurrentPage(response.data.current_page);
        }
      } catch (err) {
        throw err;
      } finally {
        $spinner.hide();
      }
    }),

    setList(data) {
      self.list = data || [];
    },

    setCurrentPage(page) {
      self.currentPage = page;
    },

    setPage(page) {
      self.setCurrentPage(page);
      self.fetchList();
    },

    setLimit(limit) {
      self.limit = limit;
      self.setCurrentPage(1);
      self.fetchList();
    },

    setSearchValue(value) {
      self.searchValue = value;
      self.setCurrentPage(1);
      self.fetchList();
    },

    setSelectedType(type) {
      self.selectedType = type;
      self.setCurrentPage(1);
      self.fetchList();
    },

    setTotalCount(count) {
      self.totalCount = count;
    },

    // create: flow(function* (data) {
    //   const response = yield apiClient.post('/api/v3/coupons', data);
    //   return response.data;
    // }),

    create: flow(function* (params) {
      try {
        const res = yield api(v3Endpoint)
          .post(`coupons`, params)
          .then((res) => res.data);
        self.fetchList();
        // $products.setCurrentBundle(res);
      } catch (err) {
        iziToast.error({
          message: "Ошибка создания",
        });
        throw err;
      }
    }),

    edit: flow(function* (data) {
      const response = yield api(v3Endpoint)
        .put(`coupons/${data.id}`, data)
        .then((res) => res.data);
      self.fetchList();
      return response;
    }),
  }))
  .create();

// Типы купонов для использования в компонентах
export const couponTypes = [
  { value: 'const_discount', title: 'Фиксированная скидка' },
  { value: 'const_flexible_discount', title: 'Скидка на весь заказ' },
];
