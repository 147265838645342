import { types } from "mobx-state-tree";
import { api, v3Endpoint } from "../../api";
import { flow } from "mobx";
import { $spinner } from "../spinner";

export const $chatStats = types
  .model("chatStats", {
    lineChart: types.frozen({
      series: [],
      labels: [],
    }),
    pieChart: types.frozen({
      series: [],
      labels: [],
    }),
    pieChartValue: new Date(),
    pieChartSpinner: types.frozen(false),
  })
  .views((self) => ({
  }))
  .actions((self) => ({
    fetchPerDay: flow(function* (date) {
      try {
        $spinner.show();
        self.setPieChartValue(date);
        const list = yield api(v3Endpoint)
          .get("consultant/stats/perDay", {
            params: {
              date: date ? date : new Date(),
            },
          })
          .then((res) => res.data);
        self.setPieChart({
          series: Object.values(list),
          labels: Object.keys(list),
        });
      } catch (err) {
        console.error("auth user error: ", err);
        throw err;
      } finally {
        $spinner.hide();
      }
    }),
    fetchPerDays: flow(function* (date) {
      try {
        // $spinner.show();
        const list = yield api(v3Endpoint)
          .get("consultant/stats/perDays", {
            params: {
              date: date ? date : new Date(),
            },
          })
          .then((res) => res.data);

        let series = [];

        Object.entries(list).map((el) => {
          series.push({
            name: el[0],
            type: "area",
            data: Object.values(el[1]),
          });
        });

        self.setLineChart(series);

        // self.setLineChart({
        //   series: Object.values(list),
        //   labels: Object.keys(list),
        // });
      } catch (err) {
        console.error("auth user error: ", err);
        throw err;
      } finally {
        // $spinner.hide();
      }
    }),
    setPieChartValue(data) {
      self.pieChartValue = data;
    },
    setPieChart(data) {
      self.pieChart = data;
    },
    setLineChart(data) {
      self.lineChart = data;
    },
  }))
  .create();
