import {observer} from "mobx-react-lite";
import "./style.scss";
import React, {useEffect} from "react";
import {Grid, Typography} from "@mui/material";
import {$sidebar} from "store/sidebar";
import {$chatStats} from "store/chatStats/chatStats";
import ru from "date-fns/locale/ru";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {LocalizationProvider} from "@mui/lab";
import {DayPieChart} from "./dayPieChart";

const ChatStats = observer(() => {
  useEffect(() => {
    $sidebar.setPageTitle("Статистика чата");
    $chatStats.fetchPerDay();
    $chatStats.fetchPerDays();
  }, []);

  return (
    <div>
      {/*<LocalizationProvider locale={ru} dateAdapter={AdapterDateFns}>*/}
        <Typography variant="h4" component="div" sx={{mb: 2}}>
          Статистика чата
        </Typography>
        <Grid container>
          <Grid style={{background: "#fff"}} item xs={12} md={6}>
            <DayPieChart/>
          </Grid>
        </Grid>
      {/*</LocalizationProvider>*/}
    </div>
  );
});

export default ChatStats;
