import {types, flow} from "mobx-state-tree";
import {api, v3Endpoint} from "../../api";
import {$spinner} from "../spinner";
import {union} from "lodash";
import iziToast from "izitoast";

const INITIAL_STATE = {
  productsInBundleList: [],
  currentBundle: {},
  limit: 50,
  offset: 0,
  hasMore: false,
};

export const $products = types
  .model("ProductsInBundle", {
    productsInBundleList: types.frozen([]),
    currentBundle: types.frozen({}),
    limit: types.optional(types.number, 50),
    offset: types.optional(types.number, 0),
    hasMore: types.optional(types.boolean, false),
  })
  .actions((self) => {
    // Вспомогательные методы
    const resetPagination = () => {
      self.offset = 0;
      self.setProductsInBundleList([]);
    };

    const handleApiError = (error, message = "Произошла ошибка") => {
      console.error(message, error);
      throw error;
    };

    return {
      // Основные методы
      fetchProductsInBundleList: flow(function* (type) {
        if (type !== "paginate") {
          resetPagination();
        }

        try {
          $spinner.show();
          const response = yield api(v3Endpoint)
            .get(`bundles/${self.currentBundle.id}`, {
              params: {
                limit: self.limit,
                offset: self.offset,
              },
            })
            .then((res) => res.data);

          self.setHasMore(response.length >= self.limit);
          self.setProductsInBundleList(
            type === "paginate" 
              ? union(self.productsInBundleList, response) 
              : response
          );
        } catch (error) {
          handleApiError(error, "Ошибка при загрузке списка товаров");
        } finally {
          $spinner.hide();
        }
      }),

      addProductToBundle: flow(function* (productBarcode) {
        try {
          yield api(v3Endpoint)
            .post(`bundles/${self.currentBundle.id}/product`, {
              barcode: productBarcode,
            })
            .then((res) => res.data);
            
          yield self.fetchProductsInBundleList(self.currentBundle.id);
        } catch (error) {
          handleApiError(error, "Ошибка при добавлении товара");
        }
      }),

      deleteProductFromBundle: flow(function* (productId) {
        try {
          yield api(v3Endpoint)
            .delete(`bundles/${self.currentBundle.id}/product`, {
              params: { product_id: productId },
            })
            .then((res) => res.data);

          self.setProductsInBundleList(
            self.productsInBundleList.filter((el) => el.product_id !== productId)
          );
        } catch (error) {
          handleApiError(error, "Ошибка при удалении товара");
        }
      }),

      uploadBarcodesFile: flow(function* (file, overwrite = false) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('overwrite', overwrite);

        try {
          const response = yield api(v3Endpoint)
            .post(`bundles/${self.currentBundle.id}/product/upload`, formData)
            .then((res) => res.data);

          yield self.fetchProductsInBundleList(self.currentBundle.id);
          
          iziToast.success({
            message: response.message || "Файл успешно загружен",
          });
        } catch (error) {
          handleApiError(error, "Ошибка при загрузке файла");
        }
      }),

      // Сеттеры
      setProductsInBundleList(data) {
        self.productsInBundleList = data;
      },

      setCurrentBundle(data) {
        self.currentBundle = data;
      },

      setHasMore(value) {
        self.hasMore = value;
      },

      setLimit(value) {
        self.limit = value;
      },

      // Пагинация
      paginate() {
        self.offset += self.limit;
        self.fetchProductsInBundleList("paginate");
      },

      resetPaginate() {
        self.offset = 0;
      },
    };
  })
  .create(INITIAL_STATE);
