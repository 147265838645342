import { types } from "mobx-state-tree";
import { flow } from "mobx";
import { api, v3Endpoint } from "../../api";
import { $chatRooms } from "./rooms";
import { cloneDeep, union } from "lodash";
import { differenceInMinutes, parseISO } from "date-fns";
import { $chatOrders } from "./orders";
import { $sidebar } from "../sidebar";
import {$textarea} from "./textarea";

export const $chatMessages = types
  .model({
    messagesByUser: types.frozen([]),
    selectedMessage: types.frozen(null),
    limit: types.optional(types.number, 30),
    offset: types.optional(types.number, 0),
    hasMore: types.optional(types.boolean, false),
    loading: types.optional(types.boolean, false),
    unreadCount: types.frozen(null),
  })
  .views((self) => ({
    get isMessageEditAvailable() {
      return self.selectedMessage
        ? differenceInMinutes(
            new Date(),
            parseISO(self.selectedMessage.created_at)
          ) <= 28
        : false;
    },
  }))
  .actions((self) => ({
    editMessage: flow(function* (data) {
      try {
        const formData = new FormData();

        Object.entries(data).forEach((el) => {
          if (el[1]) formData.append(el[0], el[1]);
        });

        const res = yield api(v3Endpoint)
          .post(
            `consultant/${$chatRooms.selectedCustomerId}/messages/${$chatMessages.selectedMessage.id}/edit`,
            formData
          )
          .then((res) => res.data);

        const updatedMessage = {
          message: res.message.message,
          attachment_url: res.message.attachment_url,
          as_file: res.message.as_file,
        };

        const updatedMessages = self.messagesByUser.map((message) =>
          message.id === $chatMessages.selectedMessage.id ? { ...message, ...updatedMessage } : message
        );

        self.setMessagesByUser(updatedMessages);
      } catch (err) {
        throw err;
      }
    }),
    deleteMessage: flow(function* (id) {
      try {
        yield api(v3Endpoint)
          .delete(`consultant/${$chatRooms.selectedCustomerId}/messages/${id}`)
          .then((res) => res.data);

        const messages = self.messagesByUser.filter((el) => el.id !== id);
        self.setMessagesByUser(messages);
      } catch (err) {
        throw err;
      }
    }),
    getMessagesByUser: flow(function* (params = {}) {
      if (!params.paginate) {
        self.resetPaginate();
        // self.setMessagesByUser([]);
      }

      try {
        self.setLoading(true);

        const messages = yield api(v3Endpoint)
          .get(`consultant/${$chatRooms.selectedCustomerId}/messages`, {
            params: {
              limit: self.limit,
              offset: self.offset,
              ...(params.hideNotifications && {
                hideNotifications: params.hideNotifications,
              }),
            },
          })
          .then((res) => res.data);

        self.setHasMore(messages.length >= self.limit);
        self.setMessagesByUser(
          params.paginate ? union(messages, self.messagesByUser) : messages
        );

        const unreadMessages = messages.filter(
          (el) => el.viewed_at == null && el.operator_id == null
        );
        if (unreadMessages.length > 0) {
          self.markConversationViewed();
        }
      } catch (err) {
        throw err;
      } finally {
        self.setLoading(false);
      }
    }),
    markConversationViewed: flow(function* () {
      try {
        yield api(v3Endpoint)
          .post(`consultant/${$chatRooms.selectedCustomerId}/messages/viewed`)
          .then((res) => res.data);

        self.getUnreadCount();
        self.resetUnreadMessages();
      } catch (err) {
        throw err;
      }
    }),
    toggleMessageComplaint: flow(function* (id) {
      try {
        yield api(v3Endpoint)
          .post(
            `consultant/${$chatRooms.selectedCustomerId}/messages/${id}/toggleComplaint`
          )
          .then((res) => res.data);

        const updatedMessages = self.messagesByUser.map((message) =>
          message.id === id ? { ...message, is_complaint: !message.is_complaint } : message
        );

        self.setMessagesByUser(updatedMessages);
      } catch (err) {
        throw err;
      }
    }),
    // toggleBlockedUserMessages: flow(function* () {
    //   try {
    //     const res = yield api(v3Endpoint)
    //       .post(`consultant/${self.user.id}/toggleBlockMessages`)
    //       .then((res) => res.data);
    //
    //     let user = { ...self.user };
    //     let userChat = { ...self.user.chat };
    //
    //     userChat.is_chat_blocked = res.blocked_status;
    //     user.chat = userChat;
    //     self.setUser(user);
    //   } catch (err) {
    //     throw err;
    //   }
    // }),
    // toggleTakeOrderInDelivery: flow(function* () {
    //   try {
    //     const res = yield api(v3Endpoint)
    //       .post(`consultant/${self.user.id}/toggleTakeOrderInDelivery`)
    //       .then((res) => res.data);
    //
    //     let user = cloneDeep(self.user);
    //     user.chat.in_delivery = res.in_delivery_status;
    //     self.setUser(user);
    //   } catch (err) {
    //     throw err;
    //   }
    // }),

    removeRestriction: flow(function* () {
      try {
        const res = yield api(v3Endpoint)
          .post(`consultant/${self.user.id}/removeRestriction`)
          .then((res) => res.data);

        const updatedUser = { ...self.user, status: res.blocked_payment_status };
        self.setUser(updatedUser);
      } catch (err) {
        throw err;
      }
    }),
    getUnreadCount: flow(function* () {
      try {
        const count = yield api(v3Endpoint)
          .get(`consultant/unreadCount`)
          .then((res) => res.data);

        self.setUnreadCount(count.count);
      } catch (err) {
        throw err;
      }
    }),
    paginate() {
      self.offset += self.limit;
      self.getMessagesByUser({ paginate: true });
    },
    resetPaginate() {
      self.offset = 0;
    },
    setHasMore(value) {
      self.hasMore = value;
    },
    setMessagesByUser(data) {
      self.messagesByUser = data;
    },
    setSelectedMessage(data) {
      self.selectedMessage = data;
    },
    setLoading(value) {
      self.loading = value;
    },
    appendMessagesByUser(data) {
      const messages = [...self.messagesByUser];
      messages.push(data);
      self.messagesByUser = messages;
    },
    resetUnreadMessages() {
      $chatRooms.updateRoomProperty(
        "unread_messages_count",
        0,
        $chatOrders.user.id
      );
    },
    setUnreadCount(value) {
      self.unreadCount = value;

      $sidebar.setPageTitle(
        `${
          $chatMessages.unreadCount > 0 ? `(${$chatMessages.unreadCount})` : ""
        } Сообщения`
      );
    },
  }))
  .create();
