import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { 
  Box, 
  FormControl,
  Paper,
  MenuItem,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Autocomplete
} from "@mui/material";
import {DeleteOutlineOutlined, Add} from '@mui/icons-material';
import { $bundles } from "../../store/bundles/bundles";
import { $products } from "../../store/bundles/productsInBundle";
import { $modals } from "../../store/modals";
import { isMobile } from "react-device-detect";
import { 
  NEW_BUNDLE_MODAL,
  ADD_PRODUCT_TO_BUNDLE_MODAL,
  DELETE_PRODUCT_FROM_BUNDLE_MODAL,
  UPLOAD_TXT_TO_BUNDLE_MODAL,
  EDIT_BUNDLE_MODAL
} from "../../ui-components/modal/modals";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from '@mui/icons-material/Edit';

const Bundles = observer(() => {
  const [selectedBundleId, setSelectedBundleId] = useState('');
  const [selectedBundle, setSelectedBundle] = useState(null);

  useEffect(() => {
    $bundles.fetchBundles();
  }, []); // Только при первой загрузке

  // Обновляем этот useEffect
  useEffect(() => {
    if (!$modals.name && selectedBundleId) {
      const bundleExists = $bundles.list.some(b => b.id === selectedBundleId);
      if (!bundleExists) {
        setSelectedBundleId('');
        setSelectedBundle(null); // Сбрасываем выбранный бандл
      }
    }
  }, [$modals.name, $bundles.list]); // Добавляем зависимость от $bundles.list

  const handleBundleChange = async (event, newValue) => {
    if (!newValue) {
      setSelectedBundleId('');
      setSelectedBundle(null);
      return;
    }
    
    const bundleId = newValue.id;
    setSelectedBundleId(bundleId);
    setSelectedBundle(newValue); // Сохраняем выбранный бандл
    
    if (bundleId) {
      const selectedBundle = $bundles.list.find(b => b.id === bundleId);
      $products.setCurrentBundle(selectedBundle);
      await $products.fetchProductsInBundleList();
    }
  };

  const handleCreateBundle = () => {
    $modals.show(NEW_BUNDLE_MODAL);
  };

  const handleDeleteProduct = (product) => {
    $modals.show(DELETE_PRODUCT_FROM_BUNDLE_MODAL, product);
  };

  const handleAddProduct = () => {
    if (selectedBundleId) {
      $modals.show(ADD_PRODUCT_TO_BUNDLE_MODAL);
    }
  };

  const handleUploadFile = () => {
    if (selectedBundleId) {
      $modals.show(UPLOAD_TXT_TO_BUNDLE_MODAL);
    }
  };

  const handleEditBundle = () => {
    if (selectedBundleId) {
      $modals.show(EDIT_BUNDLE_MODAL, $bundles.list.find(b => b.id === selectedBundleId));
    }
  };

  const filterOptions = (options, { inputValue }) => {
    return options.filter((option) =>
      option.title.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const ButtonWrapper = ({ children }) => (
    <Box sx={isMobile ? { fontSize: 12, ml: 0, mb: 2 } : { fontSize: 18, ml: 2 }}>
      {children}
    </Box>
  );

  return (
    <Box>
      <Box sx={isMobile ? {} : { display: "flex", justifyContent: "space-between", mb: 2 }}>
        <Box sx={isMobile ? {} : { display: "flex", justifyContent: "flex-start" }}>
          <FormControl
            fullWidth={isMobile}
            size="small"
            sx={{ minWidth: 250, mb: isMobile ? 2 : 0 }}
          >
            {$bundles.list && (
              <Autocomplete
                size="small"
                clearOnBlur
                disableClearable
                value={selectedBundle}
                getOptionLabel={(option) => option.title}
                onChange={handleBundleChange}
                options={$bundles.list}
                filterOptions={filterOptions}
                renderInput={(params) => (
                  <TextField 
                    {...params} 
                    label="Выберите бандл"
                    placeholder="Поиск..."
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props} value={option.id} key={option.id}>
                    <div style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}>
                      <div style={{
                        background: option.bg_color,
                        width: 'fit-content',
                        borderRadius: '5px',
                        padding: '2px 4px',
                        color: option.text_color || '#000'
                      }}>
                        {option.title}
                      </div>
                      <div style={{
                        fontSize: '12px',
                        color: '#666'
                      }}>
                        {option.promo_type === null ? 'инфо' : 'промо'}
                      </div>
                    </div>
                  </MenuItem>
                )}
              />
            )}
          </FormControl>
          <div style={isMobile ? {} : { display: "flex", justifyContent: "flex-start" }}>
            <ButtonWrapper>
              <Button
                fullWidth={isMobile}
                variant="contained"
                startIcon={<Add />}
                onClick={handleCreateBundle}
              >
                Создать
              </Button>
            </ButtonWrapper>
            {selectedBundleId && (
              <ButtonWrapper>
                <Button
                  fullWidth={isMobile}
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={handleEditBundle}
                >
                  Редактировать
                </Button>
              </ButtonWrapper>
            )}
          </div>
        </Box>
        {selectedBundleId && (
          <div style={isMobile ? {} : { display: "flex", justifyContent: "flex-end" }}>
            <ButtonWrapper>
              <Button
                fullWidth={isMobile}
                variant="contained"
                startIcon={<Add />}
                onClick={handleAddProduct}
              >
                Добавить товар
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                fullWidth={isMobile}
                variant="contained"
                startIcon={<AttachFileIcon />}
                onClick={handleUploadFile}
              >
                Загрузить файл
              </Button>
            </ButtonWrapper>
          </div>
        )}
      </Box>

      {selectedBundleId && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID товара</TableCell>
                <TableCell>Название</TableCell>
                <TableCell>Штрихкод</TableCell>
                <TableCell>Дата добавления</TableCell>
                <TableCell>Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {$products.productsInBundleList.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.product.id}</TableCell>
                  <TableCell>{item.product.name}</TableCell>
                  <TableCell>
                    {item.product.barcodes.map(b => b.barcode).join(', ')}
                  </TableCell>
                  <TableCell>
                    {new Date(item.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDeleteProduct(item)}>
                      <DeleteOutlineOutlined color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
});

export default Bundles;
